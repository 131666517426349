<template lang="html">
  <AdministrationLayout>
    <template #header>
      <IxRes>administration.legacyRoleManagement.header</IxRes>
    </template>

    <div class="role-management">
      <LegacyRoleFilters
        v-model="filters"
        class="table-filters"
      />

      <LegacyRoleTable
        class="role-table"
        :roles="roles" :role-being-edited="roleBeingEdited"
        :filters="filters"
        @edit="roleBeingEdited = $event"
        @remove="$refs.removeModal.start($event)"
      />

      <div class="details">
        <LegacyRoleForm
          v-if="roleBeingEdited"
          v-model="roleBeingEdited" :roles="roles.map(x => x.name)"
          @save="save"
        />
      </div>
    </div>

    <FrsLoadingIndicator :requests="['admin.roles.load']" />
  </AdministrationLayout>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import AdministrationLayout from './components/AdministrationLayout'
import LegacyRoleFilters from './components/legacy-role-management/LegacyRoleFilters'
import LegacyRoleTable from './components/legacy-role-management/LegacyRoleTable'
import LegacyRoleForm from './components/legacy-role-management/LegacyRoleForm'

export default {
  components: {
    AdministrationLayout,
    LegacyRoleFilters,
    LegacyRoleTable,
    LegacyRoleForm,
    FrsLoadingIndicator
  },
  data () {
    return {
      roleBeingEdited: null,
      filters: {
        restriction: null,
        include: [
          'unrestricted',
          'restricted'
        ]
      }
    }
  },
  computed: {
    ...mapState('administration/legacyRoleManagement', [
      'roles'
    ])
  },
  methods: {
    ...mapActions('administration/legacyRoleManagement', [
      'loadAllRoles',
      'saveRole'
    ]),
    async save (role) {
      try {
        await this.saveRole(role)
        const message = this.$i18n.translate('administration.legacyRoleManagement.notifications.saveSuccess')
        notifications.success(message)

        this.roleBeingEdited = null
      } catch (error) {
        console.error(error)
        const message = this.$i18n.translate('administration.legacyRoleManagement.notifications.saveError')
        notifications.error(message)
      }
    }
  },
  created () {
    this.loadAllRoles()
  }
}
</script>

<style lang="scss" scoped>
.role-management {
  display: grid;
  grid-template:
    "header header" auto
    "filters empty" auto
    "table details" 1fr / 70% 30%;
  gap: 0.5em;

  flex: 1;
  min-height: 0;
}

.header {
  grid-area: header;
}

.filters {
  grid-area: filters;
}

.role-table {
  grid-area: table;
}

.details {
  grid-area: details;
  height: 100%;
}
</style>
