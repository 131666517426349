<template lang="html">
  <div class="filters">
    <div class="stack">
      <label>
        <IxRes>administration.legacyRoleManagement.labels.restrictionFilters</IxRes>
      </label>
      <div class="btn-group">
        <ToggleButton v-model="restriction" value="unrestricted">
          <IxRes>administration.legacyRoleManagement.buttons.includeUnrestricted</IxRes>
        </ToggleButton>
        <ToggleButton v-model="restriction" value="restricted">
          <IxRes>administration.legacyRoleManagement.buttons.includeRestricted</IxRes>
        </ToggleButton>
      </div>
    </div>
    <div class="stack">
      <label>
        <IxRes>administration.legacyRoleManagement.labels.specialRoleFilters</IxRes>
      </label>
      <div class="btn-group">
        <ToggleButton v-model="include" value="featureFlags">
          <IxRes>administration.legacyRoleManagement.buttons.includeFeatureFlags</IxRes>
        </ToggleButton>
        <ToggleButton v-model="include" value="dashboards">
          <IxRes>administration.legacyRoleManagement.buttons.includeDashboards</IxRes>
        </ToggleButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFormFieldsFromValue} from '@helpers/vue/forms'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: Object
  },
  computed: {
    ...mapFormFieldsFromValue([
      'restriction',
      'include'
    ])
  }
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: center;

  .stack {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
</style>
