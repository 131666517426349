<template lang="html">
  <div class="role-form">
    <TextInput
      v-model="name" name="name"
      disabled
      required
    >
      <template #label>
        <IxRes>administration.legacyRoleManagement.labels.name</IxRes>
      </template>
    </TextInput>

    <VueSelectize
      v-model="assignableBy" name="assignableBy"
      :options="roles"
      allow-empty multiple
    >
      <template #label>
        <IxRes>administration.legacyRoleManagement.labels.assignableBy</IxRes>
      </template>
    </VueSelectize>

    <HelpBox v-if="assignableBy.length === 1 && assignableBy[0] === name" type="warning">
      <IxRes>administration.legacyRoleManagement.hints.strictSelfRestriction</IxRes>
    </HelpBox>

    <TextInput v-model="description" name="description" multiline>
      <template #label>
        <IxRes>administration.legacyRoleManagement.labels.description</IxRes>
      </template>
    </TextInput>

    <div class="buttons">
      <IxButton cancel large @click="$emit('input', null)" />
      <IxButton save large @click="tryToSave" />
    </div>

    <FrsLoadingIndicator :requests="['admin.roles.save']" />
  </div>
</template>

<script>
import {mapFormFieldsFromValue} from '@helpers/vue/forms'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    TextInput,
    VueSelectize,
    HelpBox,
    IxButton,
    FrsLoadingIndicator
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    value: Object,
    roles: Array
  },
  computed: {
    ...mapFormFieldsFromValue([
      'name',
      'description',
      'assignableBy'
    ])
  },
  methods: {
    async tryToSave () {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      this.$emit('save', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.role-form {
  display: flex;
  flex-direction: column;
  position: relative;

  .buttons {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-between;
  }
}
</style>
