<template lang="html">
  <div class="scroll-container">
    <SimpleTable
      :columns="columns" :data="filteredRoles"
      :highlight="highlight" localize-headers
    >
      <template #assignableBy="{value}">
        <span v-if="value.length">{{ value.join(', ') }}</span>
        <div v-else class="all">
          <IxRes>administration.legacyRoleManagement.hints.assignableByAll</IxRes>
        </div>
      </template>
      <template #actions="{row}">
        <div v-if="canBeEdited(row.name)" class="actions">
          <SimpleTableAction :disabled="!!roleBeingEdited" @click="$emit('edit', row)">
            <IxRes>administration.legacyRoleManagement.buttons.edit</IxRes>
          </SimpleTableAction>
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import {canBeEdited} from './role-restrictions'

import SimpleTable from '@components/table/SimpleTable'
import SimpleTableAction from '@components/table/SimpleTableAction'

export default {
  components: {
    SimpleTable,
    SimpleTableAction
  },
  props: {
    roles: Array,
    roleBeingEdited: Object,
    filters: Object
  },
  computed: {
    columns () {
      return {
        name: {
          type: 'string',
          sortable: true,
          title: 'administration.legacyRoleManagement.labels.name'
        },
        assignableBy: 'administration.legacyRoleManagement.labels.assignableBy',
        description: 'administration.legacyRoleManagement.labels.description',
        userCount: {
          type: 'number',
          sortable: true,
          title: 'administration.legacyRoleManagement.labels.userCount'
        },
        actions: ''
      }
    },
    filteredRoles () {
      const restrictionFilters = {
        unrestricted: role => !role.assignableBy.length,
        restricted: role => role.assignableBy.length
      }

      const includeFilters = {
        featureFlags: role => role.name.startsWith('feature/'),
        dashboards: role => role.name.startsWith('dashboard-') || role.name.startsWith('power-bi-workspace/') || role.name.startsWith('power-bi-reports/')
      }

      return this.roles.filter(role => {
        const restrictionFilterResult = !this.filters.restriction || restrictionFilters[this.filters.restriction](role)

        const executeIncludeFilter = name => !includeFilters[name](role) || this.filters.include.includes(name)

        return restrictionFilterResult && Object.keys(includeFilters).every(executeIncludeFilter)
      })
    }
  },
  methods: {
    highlight (row) {
      if (!canBeEdited(row.name)) {
        return 'danger'
      }
      if (this.roleBeingEdited && this.roleBeingEdited.name === row.name) {
        return 'info'
      }
    },
    canBeEdited
  }
}
</script>

<style lang="scss" scoped>
.scroll-container {
  min-height: 0;
  overflow-y: auto;

  .all {
    opacity: 0.5;
  }

  // limit description column width
  ::v-deep tr > td:nth-child(3) {
    max-width: 300px;
  }
}
</style>
